import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { AuthenticationService } from '../common_service/user.service';
import { CommonService } from 'src/app/common_service/common.service';
import { Observable } from 'rxjs';




@Injectable({
  providedIn: 'root',
})



export class HttpService {
  redirectLocalVal: any = {};
  Api_url: string;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authenticationService: AuthenticationService,
    private commonService: CommonService
  ) {
    this.Api_url = environment.APIUrl;

  }

  get(url: string, p0?: { responseType: string; }) {

    let token = sessionStorage.getItem('token') || '';

    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')

    if (token) {
      const tokenExpired = this.isTokenExpired(token);
      if (tokenExpired) {
        // Redirect to login if token is expired
        this.commonService.error('Session expired')
        this.logout();
        // this.router.navigate(['/login']);
      }
      httpHeaders = httpHeaders.set('Authorization', `Bearer ${token}`);
    }

    let options = {
      headers: httpHeaders,
    };
    return this.http.get(url, options);
  }

  fileGet(url: string, options?: any): Observable<any> {
    return this.http.get(url, { ...options, responseType: 'blob' });
  }


  post(url: string, data: any) {

    let token = sessionStorage.getItem('token') || '';


    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Pragma', 'no-cache')

    if (token) {
      const tokenExpired = this.isTokenExpired(token);
      if (tokenExpired) {
        // Redirect to login if token is expired
        this.commonService.error('Session expired')
        this.logout();
        // this.router.navigate(['/login']);
      }
      httpHeaders = httpHeaders.set('Authorization', `Bearer ${token}`);
    }

    let options = {
      headers: httpHeaders,
    };

    return this.http.post(url, data, options);
  }

  formPost(url: string, data: any) {

    let token = sessionStorage.getItem('token') || '';

    let httpHeaders = new HttpHeaders()
    // .set('Content-Type', 'multipart/form-data')
    // .set('Access-Control-Allow-Origin', '*')
    // .set('Pragma', 'no-cache')

    if (token) {
      const tokenExpired = this.isTokenExpired(token);
      if (tokenExpired) {
        // Redirect to login if token is expired
        this.commonService.error('Session expired')
        this.logout();
        // this.router.navigate(['/login']);
      }
      httpHeaders = httpHeaders.set('Authorization', `Bearer ${token}`);
    }

    let options = {
      headers: httpHeaders,
    };

    console.log('req', options)

    return this.http.post<any>(url, data, options);
  }


  put(url: string, data: any) {

    let token = sessionStorage.getItem('token') || '';


    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')
      .set('Pragma', 'no-cache')

    if (token) {
      const tokenExpired = this.isTokenExpired(token);
      if (tokenExpired) {
        // Redirect to login if token is expired
        this.commonService.error('Session expired')
        this.logout();
        // this.router.navigate(['/login']);
      }
      httpHeaders = httpHeaders.set('Authorization', `Bearer ${token}`);
    }

    let options = {
      headers: httpHeaders,
    };

    return this.http.put(url, data, options);
  }


  formPut(url: string, data: any) {
    let token = sessionStorage.getItem('token') || '';

    let httpHeaders = new HttpHeaders()
    // .set('Content-Type', 'multipart/form-data')
    // .set('Access-Control-Allow-Origin', '*')
    // .set('Pragma', 'no-cache')

    if (token) {
      const tokenExpired = this.isTokenExpired(token);
      if (tokenExpired) {
        // Redirect to login if token is expired
        this.commonService.error('Session expired')
        this.logout();
        // this.router.navigate(['/login']);
      }
      httpHeaders = httpHeaders.set('Authorization', `Bearer ${token}`);
    }

    let options = {
      headers: httpHeaders,
    };

    return this.http.put(url, data, options);
  }

  patch(url: string, data: any) {

    let token = sessionStorage.getItem('token') || '';


    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')

    if (token) {
      httpHeaders = httpHeaders.set('Authorization', `Bearer ${token}`);
    }

    let options = {
      headers: httpHeaders,
    };
    return this.http.patch(url, data, options);
  }

  delete(url: string, params: any) {

    let token = sessionStorage.getItem('token') || '';


    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Access-Control-Allow-Origin', '*')

    if (token) {
      httpHeaders = httpHeaders.set('Authorization', `Bearer ${token}`);
    }

    let options = {
      headers: httpHeaders,
      body: params
    };
    return this.http.delete(url, options);
  }


  logout() {
    sessionStorage.clear();
    this.authenticationService.currentUserSubject.next(null);
    this.router.navigate(['/user/login']);
    // location.reload();
  }

  isTokenExpired(token: string | null): boolean {
    // Logic to check if token is expired
    // You may use libraries like jwt-decode to decode the token and extract expiration date
    // Here's a simplified example assuming token is in JWT format and expiration date is stored in 'exp' claim
    if (!token) return true;
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    const tokenExpirationDate = decodedToken.exp * 1000; // Convert expiration timestamp to milliseconds
    return tokenExpirationDate < Date.now();
  }
}
