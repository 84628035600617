<div class="page_wrapper" [ngClass]="collapseHide ? 'collapseHide' : ''" *ngIf="currentUser != null">
  <div class="sidebar_wrapper">
    <div>
      <div class="logoBrand">
        <img src="../assets/images/logo.svg" />
      </div>
      <ul>
        <li>
          <a [routerLink]="['dashboard']" [ngClass]="dashboard ? 'active' : ''" class="activeMenu"
            (click)="switchDashboard()">
            <div class="menuIcon">
              <img src="../assets/images/new_img/dashboard_icon.svg" />
            </div>
            <span>Dashboard</span>
          </a>
        </li>
        <li *ngIf="permissionCheck('WEB')">
          <a href="javascript:void(0)" type="button" (click)="switchWebsite()" [ngClass]="website? 'active' : ''">
            <div class="menuIcon">
              <img src="../assets/images/new_img/website_manager.svg" />
            </div>
            <span>Website Manager</span>
            <span class="ml_auto"><img src="../assets/images/new_img/menu_downarrow.svg" /></span>
          </a>
          <ul *ngIf="website" class="customSubmenu">
            <li *ngIf="permissionCheck('WEB-PAGE')">
              <a routerLinkActive="menuActive" [routerLink]="['website/landing-page']">Website Pages</a>
            </li>
            <li *ngIf="permissionCheck('WEB-DOMAIN')">
              <a routerLinkActive="menuActive" [routerLink]="['website/custom-domain']">Custom Domain</a>
            </li>
            <li *ngIf="permissionCheck('WEB-OPTIN')">
              <a routerLinkActive="menuActive" [routerLink]="['website/optinforms']">Opt-in Forms</a>
            </li>
          </ul>
        </li>
        <li *ngIf="permissionCheck('CALENDAR')">
          <a href="javascript:void(0)" type="button" (click)="switchCalendar()" [ngClass]="calendar ? 'active' : ''">
            <div class="menuIcon">
              <img src="../assets/images/new_img/calender_icon.svg" />
            </div>
            <span>Calendar</span>
            <span class="ml_auto"><img src="../assets/images/new_img/menu_downarrow.svg" /></span>

          </a>
          <ul *ngIf="calendar" class="customSubmenu">
            <li *ngIf="permissionCheck('CALENDAR-ACTIVE')">
              <a routerLinkActive="menuActive" [routerLink]="['calendar/activeCalendar']">Active Calendar</a>
            </li>
            <li *ngIf="permissionCheck('CALENDAR-PERSONAL')">
              <a routerLinkActive="menuActive" [routerLink]="['calendar/personalremainder']">
                Personal Reminder</a>
            </li>
          </ul>
        </li>
        <li *ngIf="permissionCheck('EMAIL')">
          <a href="javascript:void(0)" type="button" (click)="switchCampaign()" [ngClass]="campaign ? 'active' : ''">
            <div class="menuIcon">
              <img src="../assets/images/new_img/compain_manager.svg" />
            </div>
            <span>Campaign manager</span>
            <span class="ml_auto"><img src="../assets/images/new_img/menu_downarrow.svg" /></span>

          </a>

          <ul *ngIf="campaign" class="customSubmenu">


            <li *ngIf="permissionCheck('EMAIL-CONTACTS')">
              <a routerLinkActive="menuActive" [routerLink]="['email/contacts']">Contacts</a>
            </li>
            <li *ngIf="permissionCheck('EMAIL-LIST')">
              <a routerLinkActive="menuActive" [routerLink]="['email/list']">List</a>
            </li>
            <li *ngIf="permissionCheck('EMAIL-NEWSLETTER')">
              <a routerLinkActive="menuActive" [routerLink]="['email/newsletters']">Newsletters</a>
            </li>
            <li>
              <a routerLinkActive="menuActive" [routerLink]="['email/reporting']">
                Email Campaign Report</a>
            </li>
          </ul>
        </li>

        <li *ngIf="permissionCheck('SUBMIT-ARTWORK')">
          <a routerLinkActive="menuActive" (click)="switchSubmitArtwork()" [routerLink]="['submit_artwork']"
            href="javascript:void(0)" type="button" [ngClass]="submitArtwork ? 'active' : ''">
            <div class="menuIcon">
              <img src="../assets/images/new_img/submit_artwork_icon.svg" />
            </div>
            <span>Submit Artwork</span>


          </a>


        </li>

        <li *ngIf="permissionCheck('SOCIAL')">
          <a href="javascript:void(0)" type="button" (click)="switchSocial()" [ngClass]="social ? 'active' : ''">
            <div class="menuIcon">
              <img src="../assets/images/new_img/social_promo_icon.svg" />
            </div>
            <span>Social Promo</span>
            <span class="ml_auto"><img src="../assets/images/new_img/menu_downarrow.svg" /></span>

          </a>

          <ul *ngIf="social" class="customSubmenu">

            <li>
              <a routerLinkActive="menuActive" [routerLink]="['social/socialPost']">Social</a>
            </li>
            <!-- <li >
              <a routerLinkActive="menuActive" [routerLink]="['social/hash-tag']">Hashtags</a>
            </li>
            <li>
              <a routerLinkActive="menuActive" [routerLink]="['social/custom-post']">Custom Post</a>
            </li>
            <li >
              <a routerLinkActive="menuActive" [routerLink]="['social/stock-image']">Stock Images</a>
            </li> -->
          </ul>
        </li>



        <li *ngIf="currentUser.role === 'SuperAdmin'||currentUser.role === 'SiteAdminUser' ">
          <a href="javascript:void(0)" type="button" (click)="switchSettings()" [ngClass]="settings  ? 'active' : ''">
            <div class="menuIcon">
              <img src="../assets/images/new_img/setting_icon.svg" />
            </div>
            <span>Settings</span>
            <span class="ml_auto"><img src="../assets/images/new_img/menu_downarrow.svg" /></span>

          </a>

          <ul *ngIf="settings" class="customSubmenu">
            <li *ngIf="role === 'SuperAdmin'">
              <a routerLinkActive="menuActive" [routerLink]="['/setting/companies']">Companies</a>
            </li>

            <!-- <li *ngIf="role === 'SuperAdmin'">
              <a routerLinkActive="menuActive" [routerLink]="['/setting/market']">Markets</a>
            </li> -->
            <li *ngIf="role === 'SiteAdminUser'">
              <a routerLinkActive="menuActive" [routerLink]="['/setting/users']">Users</a>
            </li>
          </ul>
        </li>

      </ul>
    </div>
    <div>

      <div *ngIf="currentUser.role !== 'SuperAdmin'" class="contactUs_frame">
        <h2 class="fontsize20 whiteClr_text mb_16 fontWeight600">Have Queries ??</h2>

        <p class="fontsize14 whiteClr_text mb_24 fontWeight500">Don’t we are here to serve you</p>

        <button class="MainBtn RoyalFlycatcherCrest_btn" (click)="navTo()">Contacts Us</button>
      </div>
    </div>
  </div>
  <div class="content_wrapper">
    <div class="navbarHeader">
      <nav>
        <div class="displayFlex alignItem_center gap16">
          <div>
            <button class="hideMenuClick" (click)="collapse()">
              <img src="../assets/images/new_img/iconpark_left.svg" alt="iconpark_left" />
            </button>
          </div>
          <div>
            <ul class="custom_breadcrumb">
              <li><a href="javascript:void(0)">{{topBar.menu}}</a></li>
              <li>{{topBar.page ? "/" :""}}</li>
              <li><a href="javascript:void(0)" class="active">{{topBar.page}}</a></li>
            </ul>
          </div>


        </div>
        <div>
          <div class="user_profile" [matMenuTriggerFor]="profilemenu">
            <div>
              <button mat-icon-button [matMenuTriggerFor]="profilemenu">
                <img class="db_img" src="../assets/images/user2-160x160.jpg" />
              </button>
            </div>

            <div>
              <p>{{ currentUser.firstName }} {{ currentUser.lastName }}</p>
            </div>
            <span><img src="../assets/images/new_img/menu_downarrow.svg" /></span>
          </div>

          <mat-menu #profilemenu="matMenu" class="userdropdown">
            <button [routerLink]="['account/accountsettings']" mat-menu-item>
              <mat-icon>person</mat-icon>
              <b><span>Profile</span></b>
            </button>
            <button *ngIf="role === 'SiteAdminUser'" [routerLink]="['account/companydetails']" mat-menu-item>
              <mat-icon>edit</mat-icon>
              <b><span>Edit Company</span></b>
            </button>
            <button (click)="logout()" mat-menu-item>
              <mat-icon>logout </mat-icon>
              <b><span>Sign out</span></b>
            </button>
          </mat-menu>
        </div>
      </nav>
    </div>

    <div class="contentFrame">
      <router-outlet></router-outlet>
    </div>
  </div>

  <!-- <mat-sidenav-container class=" " autosize *ngIf="currentUser != null">
    <div class="example-sidenav-content"></div>
  </mat-sidenav-container> -->
</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 1)" size="large" color="#fff" type="ball-atom" [fullScreen]="true"></ngx-spinner>

<div *ngIf="currentUser === null || !currentUser" class="accountWrapper">
  <router-outlet></router-outlet>
</div>


<app-line-chart></app-line-chart>
<!-- <style>
  .dropdown.show a.activeMenu {
    background: linear-gradient(60deg, #ff8c21 13.4%, #ff6121 86.6%);
  }

  .dropdown.show a.activeMenu img {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(100deg) brightness(95%) contrast(110%);
  }
</style> -->
